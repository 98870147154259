<template>
	<el-dialog :title="!dataForm.contractId ? '新增合同' : '修改合同'" :close-on-click-modal="false" :visible.sync="visible"
		width="950px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px" :inline="true" class="border-form">
			<el-row class="set_border">
				<el-col :span="12" class="col-set_border">
					<el-form-item label="合同名称" prop="contractName" class="item">
						<el-input class="selItemInput" v-model="dataForm.contractName" placeholder="合同名称"
							style="width:300px"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="合同编号" prop="contractNo" class="item">
						<el-input class="selItemInput" v-model="dataForm.contractNo" placeholder="合同编号"
							style="width:300px"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="12" class="col-set_border">
					<el-form-item label="签订时间" prop="contractTime" class="item">
						<el-date-picker class="selItemInput" v-model="dataForm.contractTime" type="date"
							value-format="yyyy-MM-dd" placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="签订单位" prop="contractCompany" class="item">
						<el-input class="selItemInput" v-model="dataForm.contractCompany" placeholder="签订单位"
							style="width:300px"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="12" class="col-set_border">
					<el-form-item label="合同金额" prop="contractAmount" class="item">
						<el-input v-model="dataForm.contractAmount" placeholder="合同金额" style="width:270px"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="合同时间" prop="contractTimeList" class="item">
						<el-date-picker v-model="dataForm.contractTimeList" type="daterange" style="width:270px"
							value-format="yyyy-MM-dd" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="24">
					<el-form-item label="扫描件" class="item">
						<div class="addFileBox">
							<div v-if="dataForm.contractPath">
								<el-button v-preventReClick type="success" size="small" @click="selectFile()">查看</el-button>
								<el-button v-preventReClick type="primary" size="small" @click="delFile()">删除</el-button>
							</div>
						</div>
						<el-upload class="addFileBox" ref="uploadFile" :action="action" v-if="!dataForm.contractPath"
							:http-request="uploadFile">
							<el-button v-preventReClick class="defaultPlainBtn" plain icon="el-icon-folder-add"
								style="padding: 9px 0 !important;">添加文件</el-button>
						</el-upload>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="24">
					<el-form-item label="合同内容" prop="contractContent" class="item">
						<el-input class="selItemInput descAreaLg" type="textarea" rows="3"
							v-model="dataForm.contractContent" placeholder="请填写"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="24">
					<el-form-item label="备注" prop="contractContent" class="item">
						<el-input class="selItemInput descAreaLg" type="textarea" rows="3" v-model="dataForm.remark"
							placeholder="请填写"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<el-row class="formBtnGroup">
			<el-button v-preventReClick class="btn" type="primary" size="small" @click="dataFormSubmit()">保存</el-button>
			<el-button v-preventReClick class="rebtn" size="small" @click="visible = false">取消</el-button>
		</el-row>
	</el-dialog>
</template>

<script>
import $common from "@/utils/common.js"
export default {
	name: "ownerContract-changed-log",
	data() {

		return {
			visible: false,
			inline: false,
			action: "",
			fileList: [],
			dataForm: {
				workNo: $common.getItem("workNo"),
				subjectNo: $common.getItem("subjectNo"),
				contractId: "",
				contractTimeList: [],
				beginTime: "",
				endTime: "",
				contractAmount: "",
				contractContent: "",
				contractCompany: "",
				contractTime: "",
				contractName: "",
				contractNo: "",
				contractPath: "",
				contractFileUri: '',
				remark: "",
			},
			dataRule: {
				contractName: [
					{ required: true, message: " ", trigger: "blur" }
				],
				contractNo: [
					{ required: true, message: " ", trigger: "blur" }
				],
				contractTime: [
					{ required: true, message: " ", trigger: "blur" }
				],
				contractCompany: [
					{ required: true, message: " ", trigger: "blur" }
				],
				contractAmount: [
					{ required: true, message: " ", trigger: "blur" }
				],
				contractTimeList: [
					{ required: true, message: " ", trigger: "blur" }
				]
			},
		}
	},
	methods: {
		init(id) {
			this.fileList = [];
			this.dataForm = {
				workNo: $common.getItem("workNo"),
				subjectNo: $common.getItem("subjectNo"),
				contractId: "",
				beginTime: "",
				endTime: "",
				contractTimeList: [],
				contractAmount: "",
				contractContent: "",
				contractCompany: "",
				contractTime: "",
				contractName: "",
				contractNo: "",
				contractPath: "",
				contractFileUri: '',
				remark: "",
			};
			this.dataForm.contractId = id ? id : 0;
			if (id) {
				this.getDetail();
			}
			this.$nextTick(() => {
				this.visible = true;
			})
		},
		getDetail() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/ownercontractinfo/info/" + this.dataForm.contractId,
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.dataForm = data.body
					this.dataForm.contractTimeList = [data.body.beginTime, data.body.endTime]
				}
			});
		},
		selectFile() {
			window.open(this.dataForm.contractFileUri);
		},
		delFile() {
			this.dataForm.contractPath = "";
			this.dataForm.contractFileUri = "";
		},
		uploadFile(param) {
			const formData = new FormData();
			formData.append('file', param.file);
			this.$refs.uploadFile.clearFiles();
			this.$http.post(
				this.$store.state.httpUrl + "/file/upload/" + $common.getItem("workNo") + "/97/0",
				formData,
				{
					headers: { 'Content-Type': 'multipart/form-data' }
				},
			).then(res => {
				res = res.data
				if (res.resultCode == 200) {
					this.dataForm.contractPath = res.body.fileSavePath;
					this.dataForm.contractFileUri = res.body.fileUri;
					// this.fileList = [{
					// 	fileName: res.body.fileName,
					// 	filePath: res.body.fileSavePath,
					// 	fileUri: res.body.fileUri
					// }];
				} else {
					this.$message.info('文件上传失败')
				}
			})
		},
		dataFormSubmit() {
			if (this.dataForm.contractName == null || this.dataForm.contractName == "") {
				this.$message.error("合同名称不能为空");
				return;
			}
			if (this.dataForm.contractNo == null || this.dataForm.contractNo == "") {
				this.$message.error("合同编号不能为空");
				return;
			}
			if (this.dataForm.contractTime == null || this.dataForm.contractTime == "") {
				this.$message.error("签订时间不能为空");
				return;
			}
			if (this.dataForm.contractCompany == null || this.dataForm.contractCompany == "") {
				this.$message.error("签订单位不能为空");
				return;
			}
			if (this.dataForm.contractAmount == null || this.dataForm.contractAmount == "") {
				this.$message.error("合同金额不能为空");
				return;
			}
			if (this.dataForm.contractTimeList == null || this.dataForm.contractTimeList == "") {
				this.$message.error("合同时间不能为空");
				return;
			}

			this.$refs["dataForm"].validate(valid => {
				if (valid) {
					let _url = "/business/ownercontractinfo/save";
					if (this.dataForm.contractId && this.dataForm.contractId != 0) {
						_url = "/business/ownercontractinfo/update";
					}
					this.dataForm.beginTime = this.dataForm.contractTimeList[0];
					this.dataForm.endTime = this.dataForm.contractTimeList[1];
					this.$http({
						url: this.$store.state.httpUrl + _url,
						method: "post",
						data: this.$http.adornData(this.dataForm)
					}).then(({ data }) => {
						if (data && data.resultCode === 200) {
							this.$message({
								message: "操作成功",
								type: "success",
								duration: 1500,
								onClose: () => {
									this.visible = false;
									this.$emit("refreshDataList");
								}
							});
						} else {
							this.$message.error(data.msg);
						}
					});
				}
			});
		}
	}
}
</script>
<style lang="scss"  scoped>
.selItemInput.descAreaLg {
	width: 718px !important;
}

.border-form .el-row.set_border {
	border-bottom: 1px solid #dadada;
	border-left: 1px solid #dadada;
	border-right: 1px solid #dadada;
}

.border-form .el-row.set_border:first-child {
	border-top: 1px solid #dadada;
}

.border-form .el-row.set_border .col-set_border {
	border-right: 1px solid #dadada;
}

.item::v-deep .el-form-item__label {
	color: #00428e !important;
	margin-right: 10px !important;
	font-weight: bold !important;
	background-color: #E1F1FF;
	white-space: nowrap !important;
	border-right: 1px solid #dadada;
}

::v-deep .el-form-item {
	display: flex !important;
	align-items: stretch !important;
	margin-bottom: 0px !important;
}
</style>
